:root {
    --total-clr-typo-red: #ed0000;
    --total-clr-typo-gray: #374649;

    --total-clr-ui-gray-800: #4B7D91;
    --total-clr-ui-gray-600: #b7cbd3;
    --total-clr-ui-gray-400: #f7f9fa;

    --total-clr-solid-blue: #285aff;
    --total-clr-solid-blue-light: #60c0ff;
    --total-clr-solid-turquoise: #92EAEA;
    --total-clr-solid-green-light: #32c8c8;
    --total-clr-solid-green-flashy: #BCF453;
    --total-clr-solid-yellow: #FFE74F;
    --total-clr-solid-gray: #b7cbd3;

    --total-clr-bg-oil: linear-gradient(0deg, #ee0000 0%, #ef6f00 100%);
    --total-clr-bg-gas: linear-gradient(0deg, #4632ff 0%, #009cea 100%);
    --total-clr-bg-elec: linear-gradient(0deg, #00aa61 0%, #029beb 100%);
    --total-clr-bg-green-energy: linear-gradient(0deg, #00884a 0%, #40a900 100%);

    --total-clr-backdrop: #7098a780;

    /**********************/
    /*        GREY        */
    /**********************/
    /* 00. Colors/Grey/G1 F8F8F8  */
    --total-clr-grey1: #F8F8F8;
    /* 00. Colors/Grey/G3 E9E9E9 */
    --total-clr-grey3: #E9E9E9;

    /**********************/
    /*        OTHER       */
    /**********************/
    /* 00. Colors/White */
    --total-clr-white: #FFFFFF;
    /* 00. Colors/Transparent */

    /**********************/
    /*     FUNCTIONAL     */
    /**********************/
    /* 00. Colors/Functional/Info */
    --total-clr-info: #029beb;
    /* Functional */
    /* 00. Colors/Functional/Warning */
    --total-clr-warning: #ef6f00;
    /* Functional */
    /* 00. Colors/Functional/Error */
    --total-clr-error: #ee0000;
    /* Functional */
    /* 00. Colors/Functional/Success */
    --total-clr-success: #40a900;

    --total-clr-favorite: #FBBC04;
}

@import './app/scss/variables';
@import 'assets/theme/theme';

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

*.primary {
  color: var(--total-clr-typo-red);
}

::placeholder {
  color: var(--total-clr-typo-gray) !important;
}

html,
body,
app-root,
mat-sidenav-content {
  height: 100% !important;
  // Fixes problem with Android Chrome navbar hiding footer
  // but like this navbar doesn't hide anymore
}

body {
  font-size: 16px;
  color: var(--total-clr-typo-gray);
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

.main-content {
  width: 100%;
  padding: 0;
  margin-bottom: 1rem;
}

.text {
  color: var(--total-clr-typo-gray);

  &.light {
    color: var(--total-clr-ui-gray-800);
  }
}

.title-with-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-bullet {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #FFF;

  &.mini {
    width: 12px;
    height: 12px;
  }

  &.opened {
    background-color: var(--total-clr-success);
  }

  &.closed {
    background-color: var(--total-clr-ui-gray-800);
  }

  &.under_construction {
    background-color: var(--total-clr-warning);
  }
  &.permanently_closed {
    background-color: #000;
  }
}


.items-list {
  margin-top: .5rem;

  .item {
    margin-bottom: .5rem;
    border-bottom: 1px solid var(--total-clr-ui-gray-600);
    padding: .5rem 0;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0 none;
    }

    .item-name {
      font-family: var(--total-font-roboto-bold);
    }

    .details-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;

      >div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .item-icons {
      display: flex;
      align-items: flex-start;

      a, .fake-button-link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: var(--total-clr-typo-red);
        color: #FFF;
        width: 2rem;
        border-radius: 100%;
        height: 2rem;
        margin-left: .25rem;

        &:hover {
          text-decoration: none;
        }

        .mat-icon {
          font-size: 1.25rem;
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }
}

.favorite-star {
  color: var(--total-clr-favorite);
  padding: .5rem; // to enlarge click zone
  cursor: pointer;
  box-sizing: content-box;
  margin: -.5rem -.25rem 0 0; // -.5rem to compensate padding without adding extra whitespace
}

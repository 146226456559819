@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Nunito/Nunito-Regular.eot');
    /* IE9 */
    src: url('/assets/fonts/Nunito/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('/assets/fonts/Nunito/Nunito-Regular.woff2') format('woff2'),
        /* Modern Browsers */
        url('/assets/fonts/Nunito/Nunito-Regular.woff') format('woff'),
        /* Modern Browsers */
        url('/assets/fonts/Nunito/Nunito-Regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('/assets/fonts/Nunito/Nunito-Regular.svg#Nunito') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: 'Nunito SemiBold';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/assets/fonts/Nunito/Nunito-600.eot');
    /* IE9 */
    src: url('/assets/fonts/Nunito/Nunito-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('/assets/fonts/Nunito/Nunito-600.woff2') format('woff2'),
        /* Modern Browsers */
        url('/assets/fonts/Nunito/Nunito-600.woff') format('woff'),
        /* Modern Browsers */
        url('/assets/fonts/Nunito/Nunito-600.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('/assets/fonts/Nunito/Nunito-600.svg#Nunito') format('svg');
    /* Legacy iOS */
}

/* roboto-300 - latin-ext_latin */
@font-face {
    font-family: 'Roboto Light';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-300.eot');
    /* IE9 Compat Modes */
    src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-300.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-300.woff') format('woff'),
        /* Modern Browsers */
        url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-300.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-300.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-400 - latin-ext_latin */
@font-face {
    font-family: 'Roboto Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-400.eot');
    /* IE9 Compat Modes */
    src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-400.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-400.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-400.woff') format('woff'),
        /* Modern Browsers */
        url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-400.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-400.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-500 - latin-ext_latin */
@font-face {
    font-family: 'Roboto Medium';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-500.eot');
    /* IE9 Compat Modes */
    src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-500.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-500.woff') format('woff'),
        /* Modern Browsers */
        url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-500.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-500.svg#Roboto') format('svg');
    /* Legacy iOS */
}

/* roboto-700 - latin-ext_latin */
@font-face {
    font-family: 'Roboto Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-700.eot');
    /* IE9 Compat Modes */
    src: url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-700.woff') format('woff'),
        /* Modern Browsers */
        url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('/assets/fonts/Roboto/roboto-v27-latin-ext_latin-700.svg#Roboto') format('svg');
    /* Legacy iOS */
}

:root {
    --total-font-nunito: 'Nunito', sans-serif;
    --total-font-nunito-semibold: 'Nunito SemiBold', sans-serif;
    --total-font-roboto-light: 'Roboto Light', sans-serif;
    --total-font-roboto-regular: 'Roboto Regular', sans-serif;
    --total-font-roboto-medium: 'Roboto Medium', sans-serif;
    --total-font-roboto-bold: 'Roboto Bold', sans-serif;

    --total-font-weight-nunito: 400;
    --total-font-weight-nunito-semibold: 600;
    --total-font-weight-roboto-light: 300;
    --total-font-weight-roboto-regular: 400;
    --total-font-weight-roboto-medium: 500;
    --total-font-weight-roboto-bold: 700;
}

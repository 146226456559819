* {
  &:focus {
    outline: 0 !important;
  }
}

input {

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
}

html,
body {
  height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  line-height: 20px;
}
